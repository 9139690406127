import React from "react";
import { GenerateTaxReportWrapper } from "styles/StyledComponents/GenerateTaxReportWrapper";
import {
  Box,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";
import RadioCheckedIcon from "ui/Icons/RadioCheckedIcon";
import RadioUncheckedIcon from "ui/Icons/RadioUncheckedIcon";
interface props{
    stepIncr:()=> void
}


const GenerateTaxReport = ({stepIncr}:props) => {
  return (
    <GenerateTaxReportWrapper className="cmn_block">
      <Box className="title">
        <Typography variant="h2">Generate tax report</Typography>
      </Box>
      <Box className="tax_sec">
        <Box className="tax_progress">
            <Box className="tax_progress_wrap">
        <LinearProgress variant="determinate" value={40} />
        </Box>
        <Typography variant="body1" >Now, select the time period of the transactions you want to export.</Typography>
        </Box>
        <Box className="radio_sec">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="demo1"
            name="radio-buttons-group"
          >
            <Box className="each_block">
              <FormControlLabel
                value="demo1"
                control={
                  <Radio
                    icon={<RadioUncheckedIcon />}
                    checkedIcon={<RadioCheckedIcon />}
                    disableRipple
                  />
                }
                label="Last 3 months"
              />
            </Box>
            <Box className="each_block">
            <FormControlLabel
              value="demo2"
              control={
                <Radio
                  icon={<RadioUncheckedIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  disableRipple
                />
              }
              label="Last year (2023)"
            />
            </Box>
            <Box className="each_block">
            <FormControlLabel
              value="demo3"
              control={
                <Radio
                  icon={<RadioUncheckedIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  disableRipple
                />
              }
              label="Current year"
            />
            </Box>
            <Box className="each_block">
            <FormControlLabel
              value="demo4"
              control={
                <Radio
                  icon={<RadioUncheckedIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  disableRipple
                />
              }
              label="Custom range"
            />
            </Box>
          </RadioGroup>
        </Box>
      </Box>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <CustomButtonPrimary
          variant="contained"
          color="primary"
          sx={{ minWidth: "127px" }}
          onClick={stepIncr}
        >
          Generate Now
        </CustomButtonPrimary>
      </Stack>
    </GenerateTaxReportWrapper>
  );
};

export default GenerateTaxReport;
