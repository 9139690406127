import {
  Avatar,
  Box,
  Button,
  FormControl,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";
import assest from "json/assest";
import React from "react";
import CustomSelect from "ui/Filter/CustomSelect";
import DropDownIcon from "ui/Icons/DropDownIcon";
import PetrolIcon from "ui/Icons/PetrolIcon";

const DashboardHeaderWrapper = styled(Box)`
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .hdr_rgt {
    display: flex;
    align-items: center;
    .select_wrapper {
      padding: 12px 0px;
      border: 1px solid ${primaryColors.colorEEEEEE};
      border-radius: 10px;
      display: flex;
      align-items: center;
      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
      .cmn-select {
        display: flex;
        align-items: center;
      }
    }
    .MuiSelect-select {
      padding: 0 !important;
      color: ${primaryColors.textPrimaryColor};
      font-weight: 600;
      font-size: 14px;
    }
    .MuiInputBase-root {
      svg {
        margin-left: 3px;
      }
    }
    .cmn-select {
      padding: 0 16px;
      border-right: 1px solid ${primaryColors.colorEEEEEE};
      &:last-child {
        border-right: 0;
      }
      i {
        display: inline-block;
        margin-right: 4px;
        line-height: 0;
      }
    }
  }
  .profileDetails {
    background-color: ${primaryColors.white};
    margin-left: 8px;
    border-radius: 10px;
    padding: 6px 10px;
    border: 1px solid ${primaryColors.colorEEEEEE};
  }
  h2 {
    font-size: 30px;
    font-weight: 600;
  }
  .profile_avatar_block {
    .MuiTypography-root {
      font-weight: 600;
      font-size: 14px;
    }
  }
`;

export default function DashboardHeader() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const [currency, setCurrency] = React.useState("");
  const handleChange2 = (event: SelectChangeEvent) => {
    setCurrency(event.target.value as string);
  };

  return (
    <DashboardHeaderWrapper>
      <Typography variant="h2">Overview</Typography>
      <Box className="hdr_rgt">
        <Box className="select_wrapper">
          <Box className="cmn-select">
            <i>
              <PetrolIcon />
            </i>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
              displayEmpty
              IconComponent={() => <DropDownIcon width="7" height="4" />}
            >
              <MenuItem value="">7</MenuItem>
              <MenuItem value={20}>9</MenuItem>
              <MenuItem value={30}>8</MenuItem>
            </Select>
          </Box>
          <Box className="cmn-select">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currency}
              onChange={handleChange2}
              displayEmpty
              IconComponent={() => <DropDownIcon width="7" height="4" />}
            >
              <MenuItem value="">USD</MenuItem>
              <MenuItem value={20}>INR</MenuItem>
              <MenuItem value={30}>EUR</MenuItem>
            </Select>
          </Box>
        </Box>

        <Stack direction="row" alignItems="center" className="profileDetails">
          <img src={assest.user} alt="" className="profile_avatar" />

          <Box className="profile_avatar_block">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              endIcon={<DropDownIcon width="9" height="5" />}
              disableRipple
            >
              <Typography variant="caption" className="avatr_name">
                Welcome, John
              </Typography>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="profileMenu"
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>demo</MenuItem>

              <MenuItem>Logout</MenuItem>
            </Menu>
          </Box>
        </Stack>
      </Box>
    </DashboardHeaderWrapper>
  );
}
