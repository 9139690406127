import React from "react";

const ExpandIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 12.6667H0.791667C0.316667 12.6667 0 12.35 0 11.875V7.91667C0 7.44167 0.316667 7.125 0.791667 7.125C1.26667 7.125 1.58333 7.44167 1.58333 7.91667V11.0833H4.75C5.225 11.0833 5.54167 11.4 5.54167 11.875C5.54167 12.35 5.225 12.6667 4.75 12.6667ZM11.875 5.54167C11.4 5.54167 11.0833 5.225 11.0833 4.75V1.58333H7.91667C7.44167 1.58333 7.125 1.26667 7.125 0.791667C7.125 0.316667 7.44167 0 7.91667 0H11.875C12.35 0 12.6667 0.316667 12.6667 0.791667V4.75C12.6667 5.225 12.35 5.54167 11.875 5.54167Z"
        fill="black"
      />
    </svg>
  );
};

export default ExpandIcon;
