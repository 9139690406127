import { BoxProps, Grid } from '@mui/material'
import Assets from 'components/Assets/Assets'
import GraphSec from 'components/GraphSec/GraphSec'
import HistorySec from 'components/HistorySec/HistorySec'
import React from 'react'
import { TokenSecWrapper } from 'styles/StyledComponents/TokenSecWrapper'

const TokenSec:React.FC<BoxProps> = ({...props}) => {
  return (
    <TokenSecWrapper>
        <Grid container spacing={4}>
            <Grid item lg={7} xs={12}>
              <GraphSec/>
            </Grid>
            <Grid item lg={5} xs={12}>
                <HistorySec/>
            </Grid>
            <Grid item xs={12}>
              <Assets/>
            </Grid>
        </Grid>
    </TokenSecWrapper>
  )
}

export default TokenSec