import React from "react";

export default function PetrolIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 5.20875C17.9788 4.04764 17.5068 2.94032 16.6838 2.121L14.7803 0.21975C14.6388 0.0831315 14.4493 0.00753566 14.2527 0.00924447C14.0561 0.0109533 13.8679 0.08983 13.7289 0.228886C13.5898 0.367942 13.511 0.556051 13.5092 0.752699C13.5075 0.949347 13.5831 1.1388 13.7197 1.28025L15 2.5605V4.5C15 4.89782 15.158 5.27936 15.4393 5.56066C15.7206 5.84196 16.1022 6 16.5 6V13.5C16.5 13.6989 16.421 13.8897 16.2803 14.0303C16.1397 14.171 15.9489 14.25 15.75 14.25C15.5511 14.25 15.3603 14.171 15.2197 14.0303C15.079 13.8897 15 13.6989 15 13.5V12.75C15 12.1533 14.7629 11.581 14.341 11.159C13.919 10.7371 13.3467 10.5 12.75 10.5H12V3.75C11.9988 2.7558 11.6033 1.80267 10.9003 1.09966C10.1973 0.396661 9.2442 0.00119089 8.25 0L3.75 0C2.7558 0.00119089 1.80267 0.396661 1.09966 1.09966C0.396661 1.80267 0.00119089 2.7558 0 3.75L0 14.25C0.00119089 15.2442 0.396661 16.1973 1.09966 16.9003C1.80267 17.6033 2.7558 17.9988 3.75 18H8.25C9.2442 17.9988 10.1973 17.6033 10.9003 16.9003C11.6033 16.1973 11.9988 15.2442 12 14.25V12H12.75C12.9489 12 13.1397 12.079 13.2803 12.2197C13.421 12.3603 13.5 12.5511 13.5 12.75V13.5C13.5 14.0967 13.7371 14.669 14.159 15.091C14.581 15.5129 15.1533 15.75 15.75 15.75C16.3467 15.75 16.919 15.5129 17.341 15.091C17.7629 14.669 18 14.0967 18 13.5C18 13.5 18 5.22225 18 5.20875ZM3.75 1.5H8.25C8.84674 1.5 9.41903 1.73705 9.84099 2.15901C10.2629 2.58097 10.5 3.15326 10.5 3.75V6.75H1.5V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5ZM10.5 14.25C10.5 14.8467 10.2629 15.419 9.84099 15.841C9.41903 16.2629 8.84674 16.5 8.25 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V8.25H10.5V14.25Z"
        fill="black"
      />
    </svg>
  );
}
