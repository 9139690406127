import React from "react";
import { GenerateTaxReportWrapper } from "styles/StyledComponents/GenerateTaxReportWrapper";
import {
  Box,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";
import RadioCheckedIcon from "ui/Icons/RadioCheckedIcon";
import RadioUncheckedIcon from "ui/Icons/RadioUncheckedIcon";

interface props{
  stepIncr:()=> void
}


const GenerateTaxReport2 = ({stepIncr}:props) => {
  return (
    <GenerateTaxReportWrapper className="cmn_block">
      <Box className="title">
        <Typography variant="h2">Generate tax report</Typography>
      </Box>
      <Box className="tax_sec">
        <Box className="tax_progress">
            <Box className="tax_progress_wrap">
        <LinearProgress variant="determinate" value={40} />
        </Box>
        <Typography variant="body1" >Your spreadsheet that contains transfers for the transactions during the last 3 months and their gas fees was generated. Your total deductible tax in USD is 152.45. Use the following button to generate the invoice.</Typography>
        </Box>
     
      </Box>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <CustomButtonPrimary
          variant="contained"
          color="primary"
          sx={{ minWidth: "127px" }}
          onClick={stepIncr}
        >
        Next
        </CustomButtonPrimary>
      </Stack>
    </GenerateTaxReportWrapper>
  );
};

export default GenerateTaxReport2;
