//  MUI pallete colors will be listed here

import { PaletteMode, PaletteOptions } from "@mui/material";

export const primaryColors = {
  primary: "#FFC815",
  primary_600: "#B4B4B4",
  secondary: "#FF8EB2",
  secondaryBorder: "#F380A5",
  info: "#7CD1D2",
  infoBorder: "#67C1C2",
  disabledBg: "#DBE0E8",
  textDisabled: "#8F98A8",
  errorMain: "#FE1212",
  errorLight: "#FFECF2",
  white: "#fff",
  black: "#000",
  bodyColor: "#F4F6F8",
  mainFontColor: "#8F98A8",
  textPrimaryColor: "#201C1C",
  borderprimary: "#DBE0E8",
  border_primary: "#DBE0E8",
  warning_color: "#FFEFD7",
  success_color: "#D4FEFF",
  text_success: "#3C8183",
  warning_text: "#6F4F1F",
  deepGreen: "#3c8183",
  lightGreen: "#D4FEFF",
  danger_text: "#9B3858",
  warningMain: "rgba(255, 167, 33, 1)",
  pendingColor: "#FFEFD7",
  pendingTextColor: "#6F4F1F",
  textDanger: "#9B3858",
  dangerColor: "#FFECF2",

  // borderprimary:"#DBE0E8",
  chipErrorBg:'#FFECF2',
  chipErrorText:'#9B3858',
  cardShadow:'#0707070F',
  colora9a9a9:"#a9a9a9",
  color201C1C:"#201C1C",
  colorf7f7f5:"#f7f7f5",
  color161f21:"#161f21",
  color646464:"#646464",
  colorF3F3F3:"#F3F3F3",

  color161F21: "#161F21",
  color9d9d9d:"#9d9d9d",
  colorcbcbcb:"#cbcbcb",
  colore9e9e9:"#e9e9e9",
  color616161:"#616161",
  colorBEBEBE: "#BEBEBE",
  colorEEEEEE: "#EEEEEE",
  colord50000:"#d50000",
  colorefefef:"#efefef",
  colore64a4a:"#e64a4a",
  colorAEDB07:"#AEDB07",
  colorEBEBEB:"#EBEBEB",
  colorECE9F1:"#ECE9F1",
  colorD50000:"#D50000",
  color05A71E:"#05A71E",
};

export const pallete = (mode: PaletteMode): PaletteOptions => {
  return {
    mode,
    background: {
      default: mode === "light" ? "#f5f8fa" : "#000",
      paper: mode === "light" ? "#fff" : "#000",
    },
    //global
    primary: {
      main: primaryColors.primary,
      dark: primaryColors.primary_600,
    },
    secondary: {
      main: primaryColors.secondary,
    },
    info: {
      main: primaryColors.info,
    },
    error: {
      main: primaryColors.errorMain,
    },
    warning: {
      main: primaryColors.warningMain,
    },

    text: {
      primary: primaryColors.disabledBg,
    },
    common: {
      black: "#000",
      white: "#fff",
    },
  };
};
