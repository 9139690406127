import { AccountCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";
import assest from "json/assest";
import React, { useState } from "react";
import InputFieldCommon from "ui/CommonInput/CommonInput";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";
import ArrowIcon from "ui/Icons/ArrowIcon";
import AssetsIcon from "ui/Icons/AssetsIcon";
import EthariumIcon from "ui/Icons/EthariumIcon";
import RightArrowIcon from "ui/Icons/RightArrowIcon";
import SearchIcon from "ui/Icons/SearchIcon";
import TaxIcon from "ui/Icons/TaxIcon";
import TransacsionIcon from "ui/Icons/TransacsionIcon";

const HistoryTabWrap = styled(Box)`
  padding: 35px 24px;
  border-radius: 20px;
  background-color: ${primaryColors.white};

  .btn_grp_top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    button {
      border-radius: 10px;
      border: 1px solid ${primaryColors.primary};
      font-weight: 600;
      min-width: 150px;
      padding: 6px 10px;
      margin-right: 14px;
      svg {
        margin-right: 8px;
      }
    }
  }
  .search_sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .MuiFormControl-root {
      max-width: 460px;
      background-color: ${primaryColors.colorF3F3F3};
      border-radius: 10px;
      height: 46px;
      justify-content: center;
    }
  }
  .each_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 30px;
    border-radius: 20px;
    transition: all 0.4s;
    &:hover {
      box-shadow: 0px 4px 55px -8px rgba(0, 0, 0, 0.07);
    }
    .list {
      width: 25%;
      padding: 0 15px;
      i {
        line-height: 0;
        position: relative;
      }
      .icon_content {
        margin-left: 12px;
      }
    }
    .icon_box1 {
      h5 {
        font-size: 16px;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        color: ${primaryColors.colora9a9a9};
      }
    }
    .icon_box2 {
      i {
        width: 65px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: #fff;
        border: 1px solid rgba(205, 205, 205, 0.16);
        box-shadow: 0px 5px 28.8px -6px rgba(0, 0, 0, 0.09);
      }
      h5 {
        font-size: 16px;
        font-weight: 400;
        color: ${primaryColors.colorD50000};
      }
      p {
        font-size: 13px;
        color: ${primaryColors.color616161};
      }
      svg {
        margin-left: auto;
      }
    }
    .icon_box3 {
      i {
        width: 65px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: #fff;
        border: 1px solid rgba(205, 205, 205, 0.16);
        box-shadow: 0px 5px 28.8px -6px rgba(0, 0, 0, 0.09);
      }
      h5 {
        font-size: 16px;
        font-weight: 400;
        color: ${primaryColors.color05A71E};
      }
      p {
        font-size: 13px;
        color: ${primaryColors.color616161};
      }
    }
    .icon_box1 {
      svg {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .icon_box4 {
      h5 {
        font-size: 13px;
        font-weight: 400;
      }
      p {
        font-size: 13px;
        font-weight: 600;
        color: ${primaryColors.colora9a9a9};
      }
    }
  }
  .trade_box {
    border-top: 1px solid ${primaryColors.colorECE9F1};
    padding-top: 20px;
    padding-bottom: 20px;
    h4 {
      font-size: 14px;
      color: ${primaryColors.color646464};
      margin-bottom: 15px;
    }
  }
  .wallet_list{
    margin-left: auto;
  }
`;

interface props {
  stepIncr:()=> void
}

export default function HistoryTab({stepIncr}:props) {
  return (
    <HistoryTabWrap>
      <Box className="btn_grp_top">
        <Button>
          <TransacsionIcon />
          Transaction
        </Button>
        <Button>
          <AssetsIcon />
          All Assets
        </Button>
      </Box>
      <Box className="search_sec">
        <InputFieldCommon
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <CustomButtonPrimary variant="contained" color="primary" onClick={stepIncr} sx={{minWidth:'182px',padding:'16px 35px !important'}}>
          <>
            <TaxIcon /> Generate tax
          </>
        </CustomButtonPrimary>
      </Box>
      <Box className="trade_box">
        <Typography variant="h4">April 16, 2024</Typography>
        <Box className="each_box">
          <Stack direction="row" alignItems="center" className="icon_box1 list">
            <i>
              <img src={assest.tradeImage2} alt="icon" />
              <EthariumIcon />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">Received</Typography>
              <Typography>11:33 am</Typography>
            </Box>
          </Stack>

          <Stack direction="row" alignItems="center" className="icon_box3 list">
            <i>
              <img src={assest.EthCoin} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">+0.05 ETH</Typography>
              <Typography>$152.50</Typography>
            </Box>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            className="icon_box4 wallet_list list "
          >
            <i>
              <img src={assest.demoImage} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">From</Typography>
              <Typography>
                015w2548911 wallet <ArrowIcon />
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box className="trade_box">
        <Typography variant="h4">April 10, 2024</Typography>
        <Box className="each_box">
          <Stack direction="row" alignItems="center" className="icon_box1 list">
            <i>
              <img src={assest.tradeImage} alt="icon" />
              <EthariumIcon />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">Trade</Typography>
              <Typography>08:42 PM</Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box2 list">
            <i>
              <img src={assest.USDC} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">-0.177 USDC</Typography>
              <Typography>$0.73</Typography>
            </Box>
            <RightArrowIcon />
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box3 list">
            <i>
              <img src={assest.EthCoin} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">+0.00005 ETH</Typography>
              <Typography>$0.73</Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box4 list">
            <i>
              <img src={assest.tradeLogo} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">Application</Typography>
              <Typography>1inch</Typography>
            </Box>
          </Stack>
        </Box>
        <Box className="each_box">
          <Stack direction="row" alignItems="center" className="icon_box1 list">
            <i>
              <img src={assest.tradeImage} alt="icon" />
              <EthariumIcon />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">Trade</Typography>
              <Typography>08:42 PM</Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box2 list">
            <i>
              <img src={assest.USDC} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">-0.177 USDC</Typography>
              <Typography>$0.73</Typography>
            </Box>
            <RightArrowIcon />
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box3 list">
            <i>
              <img src={assest.EthCoin} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">+0.00005 ETH</Typography>
              <Typography>$0.73</Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box4 list">
            <i>
              <img src={assest.tradeLogo} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">Application</Typography>
              <Typography>1inch</Typography>
            </Box>
          </Stack>
        </Box>
        <Box className="each_box">
          <Stack direction="row" alignItems="center" className="icon_box1 list">
            <i>
              <img src={assest.tradeImage} alt="icon" />
              <EthariumIcon />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">Trade</Typography>
              <Typography>08:42 PM</Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box2 list">
            <i>
              <img src={assest.EthCoin} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">-0.177 USDC</Typography>
              <Typography>$0.73</Typography>
            </Box>
            <RightArrowIcon />
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box3 list">
            <i>
              <img src={assest.USDC} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">+0.00005 ETH</Typography>
              <Typography>$0.73</Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box4 list">
            <i>
              <img src={assest.tradeLogo} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">Application</Typography>
              <Typography>1inch</Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box className="trade_box">
        <Typography variant="h4">April 8, 2024</Typography>
        <Box className="each_box">
          <Stack direction="row" alignItems="center" className="icon_box1 list">
            <i>
              <img src={assest.tradeImage} alt="icon" />
              <EthariumIcon />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">Trade</Typography>
              <Typography>08:42 PM</Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box2 list">
            <i>
              <img src={assest.EthCoin} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">-0.0002 ETH</Typography>
              <Typography>$0.73</Typography>
            </Box>
            <RightArrowIcon />
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box3 list">
            <i>
              <img src={assest.DAI} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">+0.727 DAI</Typography>
              <Typography>$0.73</Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" className="icon_box4 list">
            <i>
              <img src={assest.tradeLogo} alt="icon" />
            </i>
            <Box className="icon_content">
              <Typography variant="h5">Application</Typography>
              <Typography>1inch</Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </HistoryTabWrap>
  );
}
