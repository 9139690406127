import React from "react";

export default function OverviewIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66667 14C3.22464 14 2.80072 13.8244 2.48816 13.5118C2.17559 13.1993 2 12.7754 2 12.3333V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 12.3333C0 13.3058 0.386308 14.2384 1.07394 14.9261C1.76158 15.6137 2.69421 16 3.66667 16H15C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15C16 14.7348 15.8946 14.4804 15.7071 14.2929C15.5196 14.1054 15.2652 14 15 14H3.66667Z"
        fill="white"
      />
      <path
        d="M13 12C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.00001C14 6.73479 13.8946 6.48043 13.7071 6.2929C13.5196 6.10536 13.2652 6.00001 13 6.00001C12.7348 6.00001 12.4804 6.10536 12.2929 6.2929C12.1054 6.48043 12 6.73479 12 7.00001V11C12 11.2652 12.1054 11.5196 12.2929 11.7071C12.4804 11.8946 12.7348 12 13 12ZM5 12C5.26522 12 5.51957 11.8946 5.70711 11.7071C5.89464 11.5196 6 11.2652 6 11V7.00001C6 6.73479 5.89464 6.48043 5.70711 6.2929C5.51957 6.10536 5.26522 6.00001 5 6.00001C4.73478 6.00001 4.48043 6.10536 4.29289 6.2929C4.10536 6.48043 4 6.73479 4 7.00001V11C4 11.2652 4.10536 11.5196 4.29289 11.7071C4.48043 11.8946 4.73478 12 5 12ZM9 12C9.26522 12 9.51957 11.8946 9.70711 11.7071C9.89464 11.5196 10 11.2652 10 11V3.66667C10 3.40146 9.89464 3.1471 9.70711 2.95956C9.51957 2.77203 9.26522 2.66667 9 2.66667C8.73478 2.66667 8.48043 2.77203 8.29289 2.95956C8.10536 3.1471 8 3.40146 8 3.66667V11C8 11.2652 8.10536 11.5196 8.29289 11.7071C8.48043 11.8946 8.73478 12 9 12Z"
        fill="white"
      />
    </svg>
  );
}
