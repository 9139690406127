import React from "react";

const MenuIcon = () => {
  return (
    <svg
      width="11"
      height="3"
      viewBox="0 0 11 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 3C6.19977 3 6.76923 2.43054 6.76923 1.73077C6.76923 1.031 6.19977 0.461538 5.5 0.461538C4.80023 0.461538 4.23077 1.031 4.23077 1.73077C4.23077 2.43054 4.80023 3 5.5 3ZM9.73077 3C10.4305 3 11 2.43054 11 1.73077C11 1.031 10.4305 0.461538 9.73077 0.461538C9.031 0.461538 8.46154 1.031 8.46154 1.73077C8.46154 2.43054 9.031 3 9.73077 3ZM1.26923 3C1.969 3 2.53846 2.43054 2.53846 1.73077C2.53846 1.031 1.969 0.461538 1.26923 0.461538C0.569462 0.461538 0 1.031 0 1.73077C0 2.43054 0.569462 3 1.26923 3Z"
        fill="#262626"
      />
    </svg>
  );
};

export default MenuIcon;
