import { HistoryItemProps } from "components/HistorySec/HistorySec";
import assest from "json/assest";

export const historyList:HistoryItemProps[] = [
    {
        transactionType:'Received',
        date:"Apr 16",
        amount:"0.05",
        currency:"ETH",
        info:"",
        icon:assest?.EthCoin,
    },
    {
        transactionType:'Trade',
        date:"Apr 10",
        amount:"0.00005",
        currency:"ETH",
        info:"-0.177 USDC",
        icon:assest?.EthCoin,
    },
    {
        transactionType:'Received',
        date:"Apr 16",
        amount:"0.05",
        currency:"ETH",
        info:"",
        icon:assest?.EthCoin,
    },
    {
        transactionType:'Trade',
        date:"Apr 10",
        amount:"0.00005",
        currency:"ETH",
        info:"-0.177 USDC",
        icon:assest?.EthCoin,
    },
    {
        transactionType:'Trade',
        date:"Apr 10",
        amount:"0.356",
        currency:"USDC",
        info:"-0.0001 ETH",
        icon:assest?.USDC,
    },
    {
        transactionType:'Trade',
        date:"Apr 8",
        amount:"0.727",
        currency:"USDC",
        info:"-0.0002 ETH",
        icon:assest?.DAI,
    },
]

interface AssetInfo {
    assetName: string;
    assetType: string;
    assetIcon: string;
    assetTypeIcon: string;
    price: string;
    balance: string;
    value: string;
    profitOrLossPercentage: string;
    profitOrLossValue: string;
}

export const assetsTableList:AssetInfo[] = [
    {
        assetName:'Ethereum',
        assetType:"Ethereum",
        assetIcon:assest?.EthCoin,
        assetTypeIcon:assest?.EthCoin,
        price:'3098.68',
        balance:'0.051 ETH',
        value:'159.14',
        profitOrLossPercentage:'-4.50%',
        profitOrLossValue:'7.50'
    },
    {
        assetName:'USD Coin',
        assetType:"Ethereum",
        assetIcon:assest?.USDC,
        assetTypeIcon:assest?.EthCoin,
        price:'3098.68',
        balance:'14.103 USDC',
        value:'159.14',
        profitOrLossPercentage:'-4.50%',
        profitOrLossValue:'7.50'
    },
    {
        assetName:'Dai Stablecoin',
        assetType:"Ethereum",
        assetIcon:assest?.DAI,
        assetTypeIcon:assest?.EthCoin,
        price:'3098.68',
        balance:'4.295 DAI',
        value:'159.14',
        profitOrLossPercentage:'-4.50%',
        profitOrLossValue:'7.50'
    },
    {
        assetName:'Ethereum',
        assetType:"Ethereum",
        assetIcon:assest?.EthCoin,
        assetTypeIcon:assest?.EthCoin,
        price:'3098.68',
        balance:'0.051 ETH',
        value:'159.14',
        profitOrLossPercentage:'-4.50%',
        profitOrLossValue:'7.50'
    },
]