import styled from "@emotion/styled";
import { Box } from "@mui/material";
import DashboardHeader from "Layout/DashboardHeader/DashboardHeader";
import DashboardSidebar from "Layout/DashboardSidebar/DashboardSidebar";
import { primaryColors } from "Theme/_muiPalette";

import React from "react";

const DashboardWrapperStyle = styled(Box)`
  height: 100vh;
  padding: 30px;
  background-color: ${primaryColors.colorf7f7f5};
  .dashboard_outr {
    height: 100%;
    display: flex;
    .dash_lft {
      width: 250px;
      position: fixed;
      left: 30px;
      height: calc(100vh - 60px);
      top: 30px;
    }
    .dash_rgt {
      width: calc(100% - 250px);
      margin-left: auto;
      padding-left: 40px;
    }
  }
  .body_content {
    height: calc(100vh - 131px);
    overflow-y: auto;
  }
`;

interface props {
  children: React.ReactNode;
}

export default function DashboardWrapper({ children }: props) {
  return (
    <DashboardWrapperStyle>
      <Box className="dashboard_outr">
        <Box className="dash_lft">
          <DashboardSidebar />
        </Box>

        <Box className="dash_rgt">
          <DashboardHeader />

          <Box className="body_content">{children}</Box>
        </Box>
      </Box>
    </DashboardWrapperStyle>
  );
}
