import { Box, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";

export const GraphSecWrapper = styled(Box)`
  h2 {
    text-align: left;
    margin-bottom: 10px;
  }
  .stock_stack {
    padding: 28px 17px;
    background: ${primaryColors?.colorf7f7f5};
    border: 1px solid ${primaryColors?.colorefefef};
    border-radius: 10px;
    h3 {
      font-weight: 600;
      font-size: 35px;
    }
    .MuiChip-root {
      width: 149px;
      height: 32px;
      background: rgba(230, 74, 74, 0.12);
      border-radius: 10px;
      font-weight: 600;
      font-size: 14px;
      text-transform: capitalize;
      color: ${primaryColors?.colore64a4a};
    }
  }
  .monthly_stack {
    .MuiTabs-root {
      min-height: auto;
    }
    .MuiTabs-indicator {
      display: none;
    }
    .MuiTab-root {
      font-weight: 600;
      line-height: 1;
      text-align: center;
      text-transform: capitalize !important;
      color: ${primaryColors?.textPrimaryColor};
      padding: 7px 13px 5px;
      min-height: auto;
      font-size: 12px;
      min-width: auto;
      background: ${primaryColors?.colorEBEBEB};
      border-radius: 40px;
      &:not(:last-child) {
        margin-right: 14px;
      }
      &.Mui-selected {
        background: ${primaryColors?.color161f21};
        color: ${primaryColors?.primary};
      }
    }
  }
`;
