import React from "react";

export default function RightArrowIcon() {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 6.00002C7 6.21508 6.91597 6.43012 6.74825 6.59409L1.46804 11.7538C1.13215 12.0821 0.587565 12.0821 0.251814 11.7538C-0.0839379 11.4257 -0.0839379 10.8937 0.251814 10.5654L4.92404 6.00002L0.251977 1.43459C-0.0837747 1.10636 -0.0837747 0.574357 0.251977 0.24629C0.587728 -0.0820961 1.13231 -0.0820961 1.4682 0.24629L6.74842 5.40595C6.91616 5.57 7 5.78504 7 6.00002Z"
        fill="#B9B9B9"
      />
    </svg>
  );
}
