import { Box, Stack, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";

export const HistorySecWrapper = styled(Box)`
  .title_stack {
    margin-bottom: 20px;
    h2 {
      font-weight: 400;
      font-size: 22px;
      text-transform: capitalize;
      color: ${primaryColors?.color201C1C};
    }
    .title_stack_rgt {
      button {
        padding: 7px 16px;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
  .history_sec_body {
    ul {
      height: 420px;
      overflow-y: auto;
      padding-right: 10px;
      margin-right: -10px;
      li {
        padding: 15px 0;
        border-bottom: 1px solid ${primaryColors?.colorF3F3F3};
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
          border: 0;
        }
      }
    }
  }
`;

export const EachHistoryItemWrapper = styled(Stack)`
  width: 100%;
  .history_transaction_details {
    text-align: right;
    h5 {
      font-weight: 600;
      font-size: 18px;
      text-transform: capitalize;
    }
    p {
      font-size: 13px;
      text-transform: capitalize;
      color: ${primaryColors?.color646464};
    }
  }
  .icon_stack {
    i {
      width: 65px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: ${primaryColors?.white};
      border: 1px solid rgba(205, 205, 205, 0.16);
      box-shadow: 0px 5px 28.8px -6px rgba(0, 0, 0, 0.09);
    }
    .icon_content {
      padding-left: 17px;
      h5 {
        font-weight: 600;
        font-size: 16px;
        text-transform: capitalize;
      }
      p {
        font-size: 13px;
        text-transform: capitalize;
        color: ${primaryColors?.colora9a9a9};
      }
    }
  }
`;
