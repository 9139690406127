import React from 'react'

const WalletIcon = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.872 20.9414C26.6486 20.9414 27.2781 20.3119 27.2781 19.5353C27.2781 18.7587 26.6486 18.1292 25.872 18.1292C25.0955 18.1292 24.4659 18.7587 24.4659 19.5353C24.4659 20.3119 25.0955 20.9414 25.872 20.9414Z" fill="#FFC413"/>
    <path d="M24.4285 33.24H8.924C3.9933 33.24 0 29.2279 0 24.316V14.7733C0 9.84261 4.01205 5.8493 8.924 5.8493H24.4285C29.3592 5.8493 33.3525 9.86135 33.3525 14.7733V24.316C33.3525 29.2467 29.3405 33.24 24.4285 33.24ZM8.924 8.64274C7.30304 8.64274 5.74848 9.28666 4.60229 10.4328C3.45611 11.579 2.81218 13.1336 2.81218 14.7546V24.2972C2.81218 27.6719 5.54938 30.409 8.924 30.409H24.4285C27.8031 30.409 30.5403 27.6719 30.5403 24.2972V14.7546C30.5403 11.3799 27.8031 8.64274 24.4285 8.64274H8.924Z" fill="#FFC413"/>
    <path d="M1.40609 16.6669C0.637428 16.6669 0 16.0295 0 15.2608V7.63039C0 3.41212 3.43087 0 7.63039 0H21.9163C25.4596 0 28.3468 2.88718 28.3468 6.43053V7.25544C28.3468 8.0241 27.7094 8.66153 26.9407 8.66153C26.1721 8.66153 25.5346 8.0241 25.5346 7.25544V6.43053C25.5346 4.4245 23.9036 2.81219 21.9163 2.81219H7.63039C4.96819 2.81219 2.81218 4.98694 2.81218 7.63039V15.2608C2.81218 16.0295 2.17476 16.6669 1.40609 16.6669ZM33.3713 24.5972H25.5721C22.7599 24.5972 20.4727 22.31 20.4727 19.4978C20.4727 16.6856 22.7599 14.3984 25.5721 14.3984H33.3713V24.5972ZM25.5721 17.2106C24.316 17.2106 23.2849 18.2417 23.2849 19.4978C23.2849 20.7539 24.316 21.7851 25.5721 21.7851H30.5591V17.2106H25.5721Z" fill="#FFC413"/>
    </svg>
    
  )
}

export default WalletIcon