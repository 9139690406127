import "./App.css";

import { useRoutes } from "react-router";
import routes from "routes/Routes";

function App() {
  const element = useRoutes(routes);
  return <div className="App">{element}</div>;
}

export default App;
