import React from "react";

export default function EthariumIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill="url(#paint0_linear_44_1687)"
      />
      <path
        d="M8.99885 11.4516L5.97485 9.6651L8.99885 13.9275L12.0256 9.6651L8.99885 11.4516Z"
        fill="white"
      />
      <path
        d="M12.0239 9.09181L8.99902 10.8792L5.97412 9.09181L8.99902 4.07251L12.0239 9.09181Z"
        fill="white"
      />
      <path
        d="M12.0238 9.09181L8.99885 7.71661V4.07251L12.0238 9.09181ZM12.0256 9.66511L8.99885 13.9275V11.4516L12.0256 9.66511ZM8.99885 7.71661V10.8792L5.97485 9.09181L8.99885 7.71661Z"
        fill="#C1CCF7"
      />
      <path
        d="M12.0244 9.09178L8.99951 10.8792V7.71658L12.0244 9.09178Z"
        fill="#8198EE"
      />
      <defs>
        <linearGradient
          id="paint0_linear_44_1687"
          x1="9"
          y1="18"
          x2="9"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3E5EBD" />
          <stop offset="1" stop-color="#94AEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
