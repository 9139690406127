import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  StackProps,
  Typography,
  styled,
} from "@mui/material";
import assest from "json/assest";
import React from "react";
import {
  RadioCustomBlockWrapper,
  SelectWalletWrapper,
} from "styles/StyledComponents/SelectWalletWrapper";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";
import RadioCheckedIcon from "ui/Icons/RadioCheckedIcon";
import RadioUncheckedIcon from "ui/Icons/RadioUncheckedIcon";

interface RadioCustomBlockProps {
  image: string;
  title: string;
  price: string;
}

const RadioCustomBlock: React.FC<RadioCustomBlockProps> = ({
  image,
  title,
  price,
}) => {
  return (
    <RadioCustomBlockWrapper
      direction="row"
      alignItems="center"
      flexWrap="wrap"
    >
      <i>
        <img src={image} alt="" />
      </i>
      <Box className="radio_content">
        <Typography>{title}</Typography>
        <Typography variant="h5">${price}</Typography>
      </Box>
    </RadioCustomBlockWrapper>
  );
};

interface props{
    stepIncr:()=> void
}

const SelectWallet = ({stepIncr}:props) => {
  return (
    <SelectWalletWrapper className="cmn_block">
      <Box className="title">
        <Typography variant="h2">
          Please select your wallet to generate tax report
        </Typography>
      </Box>
      <Box className="radio_sec">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="demo1"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="demo1"
            control={
              <Radio
                icon={<RadioUncheckedIcon />}
                checkedIcon={<RadioCheckedIcon />}
              />
            }
            label={
              <RadioCustomBlock
                image={assest?.TaxImageOne}
                title="0121Wqs15.001"
                price="177.56"
              />
            }
          />
          <FormControlLabel
            value="demo2"
            control={
              <Radio
                icon={<RadioUncheckedIcon />}
                checkedIcon={<RadioCheckedIcon />}
              />
            }
            label={
              <RadioCustomBlock
                image={assest?.TaxImageTwo}
                title="0121Wqs15.001"
                price="177.56"
              />
            }
          />
          <FormControlLabel
            value="demo3"
            control={
              <Radio
                icon={<RadioUncheckedIcon />}
                checkedIcon={<RadioCheckedIcon />}
              />
            }
            label={
              <RadioCustomBlock
                image={assest?.TaxImageThree}
                title="0121Wqs15.001"
                price="177.56"
              />
            }
          />
        </RadioGroup>
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <CustomButtonPrimary variant="contained" color="primary" sx={{minWidth:'127px'}} onClick={stepIncr}>
          Next
        </CustomButtonPrimary>
      </Stack>
    </SelectWalletWrapper>
  );
};

export default SelectWallet;
