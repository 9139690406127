import { Box, Stack, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";

export const SelectWalletWrapper = styled(Box)`
  &.cmn_block {
    padding: 45px 35px;
  }
  .title {
    padding-bottom: 40px;
    border-bottom: 1px solid ${primaryColors?.colorECE9F1};
    h2 {
      font-weight: 600;
      font-size: 30px;
      color: ${primaryColors?.color161f21};
      text-align: center;
    }
  }
  .radio_sec{
    padding: 45px 0 60px;
    .MuiFormGroup-root{
    flex-direction: row;
    margin: -18px -18px;
    .MuiRadio-root{
        flex-basis: 40px;
        padding: 0;
        margin: 0 ;
        justify-content: flex-start;
    }
    .MuiFormControlLabel-label{
        flex-basis: calc(100% - 40px);
        display: inline-block;
    }
    .MuiFormControlLabel-root{
        width: calc(100% / 3);
        margin: 0;
        padding: 18px 18px;
    }
  }
  }

`;
export const RadioCustomBlockWrapper = styled(Stack)`
  padding: 7px 10px;
  background: ${primaryColors?.white};
  box-shadow: 0px 4px 55px -8px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  i {
    width: 112px;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }
  .radio_content {
    padding-left: 25px;
    p {
      font-size: 18px;
      color: ${primaryColors?.colorBEBEBE};
    }
    h5 {
      font-weight: 600;
      font-size: 20px;
    }
  }
`;
