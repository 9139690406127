import React from "react";

export default function AssetsIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 0H2.25C1.00927 0 0 1.00927 0 2.25V4.5C0 5.74073 1.00927 6.75 2.25 6.75H4.5C5.74073 6.75 6.75 5.74073 6.75 4.5V2.25C6.75 1.00927 5.74073 0 4.5 0ZM5.25 4.5C5.25 4.91345 4.91382 5.25 4.5 5.25H2.25C1.83618 5.25 1.5 4.91345 1.5 4.5V2.25C1.5 1.83655 1.83618 1.5 2.25 1.5H4.5C4.91382 1.5 5.25 1.83655 5.25 2.25V4.5ZM12.75 0H10.5C9.25928 0 8.25 1.00927 8.25 2.25V4.5C8.25 5.74073 9.25928 6.75 10.5 6.75H12.75C13.9907 6.75 15 5.74073 15 4.5V2.25C15 1.00927 13.9907 0 12.75 0ZM13.5 4.5C13.5 4.91345 13.1638 5.25 12.75 5.25H10.5C10.0862 5.25 9.75 4.91345 9.75 4.5V2.25C9.75 1.83655 10.0862 1.5 10.5 1.5H12.75C13.1638 1.5 13.5 1.83655 13.5 2.25V4.5ZM4.5 8.25H2.25C1.00927 8.25 0 9.25928 0 10.5V12.75C0 13.9907 1.00927 15 2.25 15H4.5C5.74073 15 6.75 13.9907 6.75 12.75V10.5C6.75 9.25928 5.74073 8.25 4.5 8.25ZM5.25 12.75C5.25 13.1635 4.91382 13.5 4.5 13.5H2.25C1.83618 13.5 1.5 13.1635 1.5 12.75V10.5C1.5 10.0865 1.83618 9.75 2.25 9.75H4.5C4.91382 9.75 5.25 10.0865 5.25 10.5V12.75ZM12.75 8.25H10.5C9.25928 8.25 8.25 9.25928 8.25 10.5V12.75C8.25 13.9907 9.25928 15 10.5 15H12.75C13.9907 15 15 13.9907 15 12.75V10.5C15 9.25928 13.9907 8.25 12.75 8.25ZM13.5 12.75C13.5 13.1635 13.1638 13.5 12.75 13.5H10.5C10.0862 13.5 9.75 13.1635 9.75 12.75V10.5C9.75 10.0865 10.0862 9.75 10.5 9.75H12.75C13.1638 9.75 13.5 10.0865 13.5 10.5V12.75Z"
        fill="#FFC413"
      />
    </svg>
  );
}
