import React from "react";

export default function ArrowIcon() {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00941 7.12235L6.42285 2.18758L3.01829 2.0301C2.92167 2.02602 2.82682 2.00286 2.7392 1.96195C2.65158 1.92103 2.57293 1.86318 2.50779 1.79171C2.44264 1.72025 2.39229 1.63659 2.35964 1.54557C2.32699 1.45455 2.31269 1.35797 2.31755 1.26139C2.32707 1.0681 2.41268 0.886438 2.5557 0.756069C2.69871 0.625699 2.88751 0.55722 3.08084 0.565586L7.52516 0.776348C7.91272 0.793114 8.27781 0.962909 8.54035 1.24849C8.79734 1.5357 8.9308 1.9126 8.91183 2.29752L8.71142 6.74232C8.70663 6.83845 8.68295 6.93269 8.64175 7.01967C8.60053 7.10665 8.5426 7.18466 8.47124 7.24925C8.39988 7.31384 8.3165 7.36374 8.22585 7.39611C8.13521 7.42847 8.03908 7.44267 7.94295 7.43788C7.84682 7.43309 7.75257 7.40942 7.66559 7.36821C7.57861 7.327 7.5006 7.26906 7.43601 7.1977C7.37142 7.12634 7.32152 7.04296 7.28916 6.95232C7.25679 6.86167 7.2426 6.76554 7.24738 6.66941L7.4098 3.27027L1.99636 8.20503C1.85279 8.33591 1.66311 8.4044 1.46904 8.39542C1.27497 8.38644 1.09242 8.30074 0.961544 8.15717C0.830665 8.01359 0.762181 7.82391 0.771158 7.62984C0.780135 7.43578 0.865837 7.25323 1.00941 7.12235Z"
        fill="#A9A9A9"
      />
    </svg>
  );
}
