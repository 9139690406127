import React from "react";
import { GenerateTaxReportWrapper } from "styles/StyledComponents/GenerateTaxReportWrapper";
import {
  Box,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";
import RadioCheckedIcon from "ui/Icons/RadioCheckedIcon";
import RadioUncheckedIcon from "ui/Icons/RadioUncheckedIcon";



const GenerateTaxReport3 = () => {
  return (
    <GenerateTaxReportWrapper className="cmn_block">
      <Box className="title">
        <Typography variant="h2">Generate tax report</Typography>
      </Box>
      <Box className="tax_sec">
        <Box className="tax_progress">
            <Box className="tax_progress_wrap">
        <LinearProgress variant="determinate" value={40} />
        </Box>
        <Typography variant="body1"  >You have  <strong>20.000 USD </strong>for tax. Use the following button to <br/> download the tax report.</Typography>
        </Box>
     
      </Box>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <CustomButtonPrimary
          variant="contained"
          color="primary"
          sx={{ minWidth: "127px" }}
        >
      Download
        </CustomButtonPrimary>
      </Stack>
    </GenerateTaxReportWrapper>
  );
};

export default GenerateTaxReport3;
