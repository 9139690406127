import {
  Box,
  BoxProps,
  Button,
  Chip,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { CustomTabPanel, a11yProps } from "components/CommonTab/CommonTab";
import LineChartDashed from "components/LineChart/LinechartDashed";
import React from "react";
import { GraphSecWrapper } from "styles/StyledComponents/GraphSecWrapper";
import StockIcon from "ui/Icons/StockIcon";

const GraphSec: React.FC<BoxProps> = ({ ...props }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <GraphSecWrapper className="cmn_block" {...props}>
      <Typography variant="h2">Performance</Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className="stock_stack"
      >
        <Typography variant="h3">$177.56</Typography>
        <Chip label="-4.0% ($7.53)" icon={<StockIcon />} />
      </Stack>
      <Box className="graph_sec">
      <CustomTabPanel value={value} index={0}>
        <LineChartDashed height="300px" />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
        <LineChartDashed height="300px" />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
        <LineChartDashed height="300px" />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
        <LineChartDashed height="300px" />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
        <LineChartDashed height="300px" />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
        <LineChartDashed height="300px" />
        </CustomTabPanel>

      </Box>
      <Box className="monthly_stack">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="1 hour" {...a11yProps(0)} />
          <Tab label="1 Day" {...a11yProps(1)} />
          <Tab label="1 week" {...a11yProps(2)} />
          <Tab label="1 Month" {...a11yProps(3)} />
          <Tab label="1 Year" {...a11yProps(4)} />
          <Tab label="Max" {...a11yProps(5)} />
        </Tabs>
      </Box>
    </GraphSecWrapper>
  );
};

export default GraphSec;
