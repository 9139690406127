import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box, { BoxProps } from '@mui/material/Box';
import { CommonTabWrapper } from 'styles/StyledComponents/CommonTabWrapper';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface CustomTabsProps {
    tabvalueCallback:(data:number ) => void
}

const CommonTab:React.FC<CustomTabsProps & BoxProps> = ({tabvalueCallback,...props}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    tabvalueCallback(newValue)
  };


  

  return (
    <CommonTabWrapper >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='cmn_tab_wrapper'>
          <Tab label="Tokens" {...a11yProps(0)} />
          <Tab label="History" {...a11yProps(1)} />
        </Tabs>
     {props?.children}

    </CommonTabWrapper>
  );
}

export default CommonTab