import styled from "@emotion/styled";
import { primaryColors } from "Theme/_muiPalette";
import Chart from "react-apexcharts";

const ChartWrapper = styled(Chart)`
  .apexcharts-tooltip {
    padding: 18px 14px;
    background: linear-gradient(149.82deg, #161f21 18.38%, #5e6d77 158.49%);
    box-shadow:
      0px 8px 8px rgba(50, 50, 71, 0.08),
      0px 8px 16px rgba(50, 50, 71, 0.06);
    border-radius: 15px;
    min-width: 132px;
    text-align: left;
    .arrow_box {
      h6 {
        font-weight: 600;
        font-size: 18px;
        text-transform: capitalize;
        color: ${primaryColors?.white};
      }
      p {
        font-weight: 600;
        font-size: 12px;
        text-transform: capitalize;
        color: rgba(255, 255, 255, 0.49);
      }
    }
  }
`;

interface ChartProps {
  // seriesList?: any[];
  height?: string;
  maxOverallValue?: number | string;
}
export default function LineChartDashed({ height = "100%" }: ChartProps) {
  function generateRandomArray(length: number, min: number, max: number) {
    return Array.from(
      { length },
      () => Math.floor(Math.random() * (max - min + 1)) + min
    );
  }
  const data = {
    series: [
      {
        name: "This Week Statistics",
        data: generateRandomArray(7, 0, 120),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 0,
          left: 10,
          blur: 11,
          color: "#D3CCDA",
          opacity: 1,
        },
      },
      colors: [primaryColors?.colorAEDB07],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 1,
          type: "vertical",
          opacityFrom: 1,
          opacityTo: 0,
          colorStops: [
            [
              {
                offset: 0,
                color: primaryColors?.colorAEDB07,
                opacity: 0.1,
              },
              {
                offset: 50,
                color: primaryColors?.colorAEDB07,
                opacity: 0.05,
              },

              {
                offset: 100,
                color: primaryColors?.colorAEDB07,
                opacity: 0,
              },
            ],
          ],
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 3,
        dashArray: 0,
      },
      title: {
        text: undefined,
        align: "left",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
      grid: {
        show: true,
        borderColor: "#ECE9F1",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 17,
          left: 17,
        },
      },
      markers: {
        size: 0,
        colors: [primaryColors?.black],
        strokeColors: [primaryColors?.white],
        strokeWidth: 3,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: 7,
          sizeOffset: 3,
        },
      },
      xaxis: {
        categories: [1, 2, 3, 4, 5, 6, 7],
        tooltip: {
          enabled: false,
          // formatter(val: number, opts: any) {
          //     return `${val}...`
          // },
          offsetY: 0,
          style: {
            fontSize: "14px",
            // fontFamily: `${poppins.style.fontFamily}`
          },
        },
        axisBorder: {
          show: false,
          color: "#EDEDED",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: false,
          borderType: "solid",
          color: "#EDEDED",
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          show: false,

          style: {
            fontSize: "12px",
            // fontFamily: `${poppins.style.fontFamily}`,
            fontWeight: 400,
            color: "#8590A5",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          // formatter: (value: number) => value,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: ({ series, seriesIndex, dataPointIndex }: any) => {
          return (
            `<div class="arrow_box">` +
            `<p>April 12</p>` +
            `<h6>$${series[seriesIndex][dataPointIndex]}</h6>` +
            `</div>`
          );
        },
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
        onDatasetHover: {
          highlightDataSeries: true,
        },
        x: {
          show: false,
          // format: 'dd MMM',
          formatter: undefined,
        },
        y: {
          show: false,
          formatter(value: number) {
            return `$${value}`;
          },
          title: {
            formatter: () => "",
          },
        },
        z: {
          show: false,
          formatter: undefined,
          title: "Lorem ipsum dolor sit",
        },
        marker: {
          show: false,
        },
        items: {
          display: "flex",
        },
        fixed: {
          enabled: false,
          position: "topRight",
          offsetX: "10px",
          offsetY: "0px",
        },
      },
    },
  };

  return (
    <ChartWrapper
      options={data.options as any}
      series={data.series}
      type="area"
      height={height}
      className="chart"
    />
  );
}
