import Logo from "../../public/images/logo.png";
import EthCoin from "../../public/images/eth_coin.svg";
import USDC from "../../public/images/usdc.svg";
import DAI from "../../public/images/dai.svg";
import star from "../../public/images/star.png";
import walletImage from "../../public/images/w1.png";
import user from "../../public/images/user.png";
import tradeImage from "../../public/images/t1.png";
import tradeImage2 from "../../public/images/t2.png";
import tradeLogo from "../../public/images/t3.png";
import demoImage from "../../public/images/t4.png";
import botImage from "../../public/images/bot.png";
import TaxImageOne from "../../public/images/tax_img1.png";
import TaxImageTwo from "../../public/images/tax_img2.png";
import TaxImageThree from "../../public/images/tax_img3.png";



const assest = {
  Logo,
  EthCoin,
  USDC,
  DAI,
  star,
  walletImage,
  user,
  tradeImage,
  tradeLogo,
  tradeImage2,
  demoImage,
  botImage,
  TaxImageOne,
  TaxImageTwo,
  TaxImageThree
};
export default assest;
