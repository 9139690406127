import React from "react";

export default function SearchIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7445 12.5108L11.0346 9.79973C13.0623 7.09004 12.5094 3.24964 9.79973 1.22196C7.09004 -0.805712 3.24964 -0.252831 1.22196 2.45685C-0.805712 5.16653 -0.252831 9.00694 2.45685 11.0346C4.63349 12.6634 7.62306 12.6634 9.79973 11.0346L12.5108 13.7457C12.8515 14.0863 13.4038 14.0863 13.7445 13.7457C14.0852 13.405 14.0852 12.8526 13.7445 12.5119L13.7445 12.5108ZM6.15088 10.5114C3.74264 10.5114 1.79041 8.55912 1.79041 6.15088C1.79041 3.74264 3.74264 1.79041 6.15088 1.79041C8.55912 1.79041 10.5114 3.74264 10.5114 6.15088C10.5088 8.55803 8.55805 10.5088 6.15088 10.5114Z"
        fill="#707070"
      />
    </svg>
  );
}
