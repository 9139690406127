import { Box, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";

export const AssetsWrapper = styled(Box)`
  .tab_sec {
    margin-right: 10px;
    .MuiTabs-root {
      min-height: auto;
      border: 1px solid ${primaryColors?.colorcbcbcb};
      border-radius: 10px;
    }
    .MuiTab-root {
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      text-align: center;
      text-transform: capitalize !important;
      color: ${primaryColors?.color9d9d9d};
      padding: 9px 30px;
      min-height: auto;
      &.Mui-selected {
        background: ${primaryColors?.color161f21};
        border-radius: 10px;
        color: ${primaryColors?.white};
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
  }
  .cmn_btn {
    &:not(:last-child) {
      margin-right: 10px;
    }
    button {
      width: 33px;
      height: 33px;
      min-width: auto;
      border: 1px solid ${primaryColors?.colorcbcbcb};
      background-color: transparent;
      border-radius: 10px;
      padding: 0;
    }
  }
  .asset_wallet {
    padding: 10px 10px;
    background: ${primaryColors?.colorf7f7f5};
    border-radius: 10px;
    .wallet_icon {
      width: 75px;
      height: 72px;
      background: ${primaryColors?.white};
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .wallet_info {
      padding-left: 23px;
      text-align: left;
      p {
        font-weight: 600;
        font-size: 16px;
        text-transform: capitalize;
        color: ${primaryColors?.colora9a9a9};
      }
      h5 {
        font-weight: 600;
        font-size: 25px;
        text-transform: capitalize;
        color: ${primaryColors?.textPrimaryColor};
        display: flex;
        align-items: center;
        span {
          .MuiChip-root {
            height: 25px;
            width: 73px;
            background: ${primaryColors?.white};
            border: 1px solid ${primaryColors?.colore9e9e9};
            border-radius: 30px;
            margin-left: 10px;
            .MuiChip-label {
              padding: 0;
            }
          }
        }
      }
    }
  }
  .assets_body {
    .asset_table {
      table {
        tbody {
          tr {
            td {
              > p {
                font-size: 16px;
                color: ${primaryColors?.color616161};
              }
              .value_block {
                p {
                  font-size: 16px;
                  color: ${primaryColors?.color616161};
                  &.profit_loss {
                    font-size: 13px;
                    color: ${primaryColors?.colord50000};
                    span {
                      color: inherit;
                      font-size: inherit;
                    }
                  }
                }
              }
              .assets_details {
                display: flex;
                align-items: center;
                p {
                  font-size: 16px;
                  color: ${primaryColors?.color616161};
                }
                i {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 100%;
                  width: 65px;
                  height: 65px;
                  background: ${primaryColors?.white};
                  border: 1px solid rgba(205, 205, 205, 0.16);
                  box-shadow: 0px 5px 28.8px -6px rgba(0, 0, 0, 0.09);
                }
                .assets_details_content {
                  padding-left: 15px;
                  h5 {
                    font-weight: 600;
                    font-size: 16px;
                    color: ${primaryColors?.color616161};
                  }
                  p {
                    display: flex;
                    align-items: center;
                    span {
                      width: 13px;
                      height: 13px;
                      display: flex;
                      align-items: center;
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }
        }
        thead {
          tr {
            th {
              font-weight: 600;
              font-size: 16px;
              text-transform: capitalize;
              color: ${primaryColors?.textPrimaryColor};
              border-bottom: 1px solid ${primaryColors?.colorF3F3F3};
            }
          }
        }
      }
    }
  }
`;
