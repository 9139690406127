import React from "react";

export default function DownArrowIcon() {
  return (
    <svg
      width="13"
      height="9"
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49998 8.5C6.26699 8.5 6.03403 8.40396 5.8564 8.21229L0.266684 2.17776C-0.0888948 1.79388 -0.0888948 1.1715 0.266684 0.787787C0.62212 0.404071 1.19852 0.404071 1.55412 0.787787L6.49998 6.12748L11.4459 0.787973C11.8014 0.404257 12.3778 0.404257 12.7332 0.787973C13.0889 1.17169 13.0889 1.79407 12.7332 2.17794L7.14355 8.21248C6.96584 8.40418 6.73288 8.5 6.49998 8.5Z"
        fill="#BEBEBE"
      />
    </svg>
  );
}
