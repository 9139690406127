import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";

const CustomButtonWrapper = styled(Button)`
  padding: 16px 16px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  text-transform: capitalize;
  color: ${primaryColors?.color161f21};
  &.Mui-disabled {
    background-color: ${primaryColors?.disabledBg};
    border: 1px solid ${primaryColors?.disabledBg};
  }
  &.MuiButton-outlinedInfo {
    background: ${primaryColors?.colorf7f7f5};
    border: 1px solid rgba(255, 196, 19, 0.15);
    &:hover{
      color: ${primaryColors?.primary};
    }
  }
  .MuiButton-startIcon{
    margin-left: 0;
  }
  svg{
    margin-right: 10px;
  }
`;

interface CustomButtonprops extends ButtonProps {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  buttonType?: "small" | "large";
}

const CustomButtonPrimary = ({
  children,
  className,
  buttonType,
  ...others
}: CustomButtonprops) => {
  return (
    <CustomButtonWrapper
      className={`${buttonType === "small" && "smallButton"} ${
        className ? className : ""
      }`}
      {...others}
    >
      {children}
    </CustomButtonWrapper>
  );
};

export default CustomButtonPrimary;
