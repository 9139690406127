import { Box, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";

export const GenerateTaxReportWrapper = styled(Box)`
  &.cmn_block {
    padding: 45px 35px;
  }
  .title {
    padding-bottom: 40px;
    border-bottom: 1px solid ${primaryColors?.colorECE9F1};
    h2 {
      font-weight: 600;
      font-size: 30px;
      color: ${primaryColors?.color161f21};
      text-align: center;
    }
  }
  .tax_sec {
    max-width: 700px;
    margin: 50px auto;
    .tax_progress{
        padding-bottom: 44px;
        .MuiLinearProgress-root{
            background-color: #F0F0F0;
            border-radius: 10px;
            height: 6px;
        }
        p{
            margin-top: 44px;
            font-size: 18px;
            color: ${primaryColors.textPrimaryColor};
            text-align: center;
        }
    }
  }
  .radio_sec {
    .MuiFormGroup-root {
      flex-direction: row;
      margin: -10px -10px;
      .MuiRadio-root {
        flex-basis: 40px;
        padding: 0;
        margin: 0;
        justify-content: flex-start;
      }
      .MuiFormControlLabel-label {
        flex-basis: calc(100% - 40px);
        display: inline-block;
        font-weight: 400;
        font-size: 16px;
        text-transform: capitalize;
        color: ${primaryColors?.textPrimaryColor};
      }
      .each_block {
        width: calc(100% / 2);
        padding: 10px 10px;
      }
      .MuiFormControlLabel-root {
        width: 100%;
        margin: 0;
        padding: 20px 18px;
        background: ${primaryColors?.colorf7f7f5};
        border-radius: 10px;
      }
    }
  }
`;
