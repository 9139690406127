import Overview from "pages/dashboard/overview-tokens";
import { RouteObject } from "react-router";

/* The `routes` constant is an array of objects that define the routes for a React application. Each
object in the array represents a specific route and contains two properties: `path` and `element`. */
const routes: RouteObject[] = [
  {
    path: "/dashboard",
    element: <Overview />,
  },
];

export default routes;
