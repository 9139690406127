/* eslint-disable no-script-url */
import { Box, List, ListItem, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";
import assest from "json/assest";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import ExploreIcon from "ui/Icons/ExploreIcon";
import OverviewIcon from "ui/Icons/OverviewIcon";
import SettingsIcon from "ui/Icons/SettingsIcon";
import SwapIcon from "ui/Icons/SwapIcon";

const DashboardSidebarWrap = styled(Box)`
  background: radial-gradient(
    366.01% 85.44% at 88.15% 80.42%,
    #575e64 0%,
    #161f21 38.97%
  );
  height: 100%;
  padding: 30px 10px;
  border-radius: 20px;
  position: relative;
  .logo {
    margin-bottom: 56px;
  }
  .star {
    position: absolute;
    right: 0;
    bottom: 0px;
  }

  .nav_list {
    li {
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: flex;
        align-items: center;
        color: ${primaryColors.white};
        width: 100%;
        font-size: 14px;
        font-weight: 600;

        border-radius: 10px;
        padding: 13px 15px;
        i {
          line-height: 0;
          display: inline-block;
          margin-right: 15px;
        }
        &:hover {
          background-color: ${primaryColors.primary};
          color: ${primaryColors.color161F21};
          svg {
            path {
              fill: ${primaryColors.color161F21};
            }
          }
        }
        &.active {
          background-color: ${primaryColors.primary};
          color: ${primaryColors.color161F21};
          svg {
            path {
              fill: ${primaryColors.color161F21};
            }
          }
        }
      }
    }
  }
`;

export default function DashboardSidebar() {
  const navList = [
    {
      icon: <OverviewIcon />,
      path: "/dashboard",
      title: "Overview",
    },
    {
      icon: <ExploreIcon />,
      path: "javascript:void(0)",
      title: "Explore",
    },
    {
      icon: <SwapIcon />,
      path: "javascript:void(0)",
      title: "Swap",
    },
    {
      icon: <SettingsIcon />,
      path: "javascript:void(0)",
      title: "Settings",
    },
  ];
  return (
    <DashboardSidebarWrap>
      <img src={assest.botImage} className="star" alt="" />
      <Box className="logo">
        <Link to="/dashboard">
          <img src={assest.Logo} alt="" width={146} height={32} />
        </Link>
      </Box>
      <List disablePadding className="nav_list">
        {navList.map((item) => (
          <ListItem disablePadding>
            <Link
              to={item.path}
              className={
                window.location.pathname.includes(item.path) ? "active" : ""
              }
            >
              <i>{item.icon}</i> {item.title}
            </Link>
          </ListItem>
        ))}
      </List>
    </DashboardSidebarWrap>
  );
}
