import {
  Box,
  BoxProps,
  Button,
  Chip,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { CustomTabPanel, a11yProps } from "components/CommonTab/CommonTab";
import React from "react";
import { AssetsWrapper } from "styles/StyledComponents/AssetsWrapper";
import ExpandIcon from "ui/Icons/ExpandIcon";
import MenuIcon from "ui/Icons/MenuIcon";
import WalletIcon from "ui/Icons/WalletIcon";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { assetsTableList } from "json/mock/demo.mock";

const Assets: React.FC<BoxProps> = ({ ...props }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <AssetsWrapper className="cmn_block" {...props}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ marginBottom: "25px" }}
      >
        <Typography variant="h2">Assets</Typography>
        <Stack direction="row" alignItems="center" className="assets_control">
          <Box className="tab_sec">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="By Platform" {...a11yProps(0)} />
              <Tab label="By Position" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <Box className="cmn_btn">
            <Button>
              <ExpandIcon />
            </Button>
          </Box>
          <Box className="cmn_btn">
            <Button>
              <MenuIcon />
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Box className="asset_wallet">
        <Stack
          direction="row"
          alignItems="center"
          className="wallet_asset_stack"
        >
          <i className="wallet_icon">
            <WalletIcon />
          </i>
          <Box className="wallet_info">
            <Typography>Wallet</Typography>
            <Typography variant="h5">
              $177.55
              <Typography variant="caption">
                <Chip label="100%" />
              </Typography>
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box className="assets_body">
        <CustomTabPanel value={value} index={0}>
       
          <Box className="asset_table">
            <TableContainer>
              <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Asset</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assetsTableList.map((row,index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Box className='assets_details'>
                            <i>
                                <img src={row?.assetIcon} alt="assetIcon" />
                            </i>
                            <Box className='assets_details_content'>
                                <Typography variant="h5">{row?.assetName}</Typography>
                                <Typography>
                                    <Typography variant="caption">
                                        <img src={row?.assetTypeIcon} alt="" />
                                    </Typography>
                                    {row?.assetType}
                                </Typography>
                            </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography>${row?.price}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row?.balance}</Typography>
                      </TableCell>
                      <TableCell>
                        <Box className='value_block'>
                            <Typography>${row?.value}</Typography>
                            <Typography className="profit_loss">{row?.profitOrLossPercentage}%
                                <Typography variant="caption">(${row?.profitOrLossValue})</Typography>
                            </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
        <Box className="asset_table">
            <TableContainer>
              <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Asset</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assetsTableList.map((row,index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Box className='assets_details'>
                            <i>
                                <img src={row?.assetIcon} alt="assetIcon" />
                            </i>
                            <Box className='assets_details_content'>
                                <Typography variant="h5">{row?.assetName}</Typography>
                                <Typography>
                                    <Typography variant="caption">
                                        <img src={row?.assetTypeIcon} alt="" />
                                    </Typography>
                                    {row?.assetType}
                                </Typography>
                            </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography>${row?.price}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row?.balance}</Typography>
                      </TableCell>
                      <TableCell>
                        <Box className='value_block'>
                            <Typography>${row?.value}</Typography>
                            <Typography className="profit_loss">{row?.profitOrLossPercentage}%
                                <Typography variant="caption">(${row?.profitOrLossValue})</Typography>
                            </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CustomTabPanel>
      </Box>
    </AssetsWrapper>
  );
};

export default Assets;
