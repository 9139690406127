import { Box, Button, styled, Typography } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";
import assest from "json/assest";
import React from "react";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";
import ButtonBackIcon from "ui/Icons/ButtonBackIcon";
import DownArrowIcon from "ui/Icons/DownArrowIcon";
import TrendIcon from "ui/Icons/TrendIcon";

const WalletSecWrap = styled(Box)`
  background: radial-gradient(
    38.36% 235.91% at 55.08% 100%,
    #535b61 0%,
    #161f21 51.01%
  );
  box-shadow: 0px 52px 22.7px -31px rgba(255, 196, 19, 0.05);
  border-radius: 20px;
  padding: 10px 30px 10px 10px;
  display: flex;
  align-items: center;
  figure {
    line-height: 0;
    height: 140px;
    width: 140px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .wallet_midle {
    margin-left: 34px;
    .wallet_dropdown {
      display: flex;
      align-items: center;
      p {
        color: ${primaryColors.colorBEBEBE};
        margin-right: 10px;
      }
    }
    .trend {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      border-radius: 30px;
      border: 1px solid rgba(230, 74, 74, 0.3);
      padding: 2px 17px;
      color: ${primaryColors.errorMain};
      svg {
        margin-right: 10px;
      }
    }
    h3 {
      font-size: 40px;
      color: ${primaryColors.white};
      margin: 10px 0;
    }
  }
  .btn_grp {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .back_btn {
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid rgba(238, 238, 238, 0.25);
    background-color: rgba(255, 255, 255, 0.16);
    min-width: auto;
    padding: 12px 17px;
  }
`;

export default function WalletSec() {
  return (
    <WalletSecWrap>
      <figure>
        <img src={assest.walletImage} alt="" />
      </figure>
      <Box className="wallet_midle">
        <Box className="wallet_dropdown">
          <Typography variant="body1" color="initial">
            0121Wqs15.001
          </Typography>
          <DownArrowIcon />
        </Box>
        <Typography variant="h3">$177.56</Typography>
        <Box className="trend">
          <TrendIcon />
          -4.0% ($7.53)
        </Box>
      </Box>
      <Box className="btn_grp">
        <Button className="back_btn">
          <ButtonBackIcon />
        </Button>
        <CustomButtonPrimary variant="contained" color="primary">
          Remove wallet
        </CustomButtonPrimary>
      </Box>
    </WalletSecWrap>
  );
}
