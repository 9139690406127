import DashboardWrapper from "Layout/DashboardWrapper/DashboardWrapper";
import CommonTab, { CustomTabPanel } from "components/CommonTab/CommonTab";
import HistoryTabWrapper from "components/HistoryTabWrapper/HistoryTabWrapper";
import TokenSec from "components/TokenSec/TokenSec";
import WalletSec from "components/WalletSec/WalletSec";
import React, { useCallback } from "react";

export default function Overview() {
  const [value, setValue] = React.useState(0);

  const tabvalueCallback = useCallback((data: number) => {
    setValue(data);
  }, []);

  console.log(value, "value");

  return (
    <DashboardWrapper>
      <WalletSec />
      <CommonTab tabvalueCallback={tabvalueCallback}>
        <CustomTabPanel value={value} index={0}>
          <TokenSec />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <HistoryTabWrapper />
        </CustomTabPanel>
      </CommonTab>
    </DashboardWrapper>
  );
}
