import React from "react";

export default function ButtonBackIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0566 4.50493C4.48263 4.7952 0 9.42174 0 15.0664V19L1.40737 15.7236C3.07188 12.3952 6.37435 10.267 10.0566 10.0713V14.5734L18.9869 7.27344L10.0566 0V4.50493Z"
        fill="white"
      />
    </svg>
  );
}
