import React from "react";

export default function TransacsionIcon() {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0.880953C0 0.64731 0.0928144 0.423236 0.258025 0.258025C0.423236 0.0928146 0.64731 0 0.880953 0H14.9762C15.2098 0 15.4339 0.0928146 15.5991 0.258025C15.7643 0.423236 15.8572 0.64731 15.8572 0.880953C15.8572 1.1146 15.7643 1.33867 15.5991 1.50388C15.4339 1.66909 15.2098 1.76191 14.9762 1.76191H0.880953C0.64731 1.76191 0.423236 1.66909 0.258025 1.50388C0.0928144 1.33867 0 1.1146 0 0.880953ZM0 6.16667C0 5.93303 0.0928144 5.70895 0.258025 5.54374C0.423236 5.37853 0.64731 5.28572 0.880953 5.28572H9.69048C9.92413 5.28572 10.1482 5.37853 10.3134 5.54374C10.4786 5.70895 10.5714 5.93303 10.5714 6.16667C10.5714 6.40032 10.4786 6.62439 10.3134 6.7896C10.1482 6.95481 9.92413 7.04763 9.69048 7.04763H0.880953C0.64731 7.04763 0.423236 6.95481 0.258025 6.7896C0.0928144 6.62439 0 6.40032 0 6.16667ZM0 11.4524C0 11.2187 0.0928144 10.9947 0.258025 10.8295C0.423236 10.6643 0.64731 10.5714 0.880953 10.5714H5.28572C5.51936 10.5714 5.74344 10.6643 5.90865 10.8295C6.07386 10.9947 6.16667 11.2187 6.16667 11.4524C6.16667 11.686 6.07386 11.9101 5.90865 12.0753C5.74344 12.2405 5.51936 12.3333 5.28572 12.3333H0.880953C0.64731 12.3333 0.423236 12.2405 0.258025 12.0753C0.0928144 11.9101 0 11.686 0 11.4524Z"
        fill="#FFC413"
      />
    </svg>
  );
}
