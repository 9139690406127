import { Box, styled } from '@mui/material';
import GenerateTaxReport from 'components/GenerateTaxReport/GenerateTaxReport';
import GenerateTaxReport2 from 'components/GenerateTaxReport2/GenerateTaxReport2';
import GenerateTaxReport3 from 'components/GenerateTaxReport3/GenerateTaxReport3';
import HistoryTab from 'components/HistoryTab/HistoryTab';
import SelectWallet from 'components/SelectWallet/SelectWallet';
import React, { useState } from 'react'

const HistoryTabWrapperStyle = styled(Box)`
`

const HistoryTabWrapper = () => {
    const [step, setStep] = useState<number>(1);

    const stepIncr = () =>{
      setStep((data) => data + 1)
    }
  
    const stepDecr = () =>{
      setStep((data) => data - 1)
    }
  return (
    <HistoryTabWrapperStyle>
      {
        step === 1 ? 
        <HistoryTab stepIncr={stepIncr}/>
        : step === 2 ? 
        <SelectWallet stepIncr={stepIncr}/>
        : step === 3 ?
        <GenerateTaxReport stepIncr={stepIncr}/>
        : step === 4 ?
        <GenerateTaxReport2 stepIncr={stepIncr}/>
        : step === 5 ?
        <GenerateTaxReport3/>
        : null
      }
    </HistoryTabWrapperStyle>
  )
}

export default HistoryTabWrapper