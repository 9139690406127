import { Box, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";

export const CommonTabWrapper = styled(Box)`
  margin-top: 30px;

  .MuiTabs-root{
    &.cmn_tab_wrapper{
      margin-bottom: 25px;
      .MuiTab-root {
    padding: 14px 85px;
    font-weight: 600;
    font-size: 15px;
    line-height: 1;
    text-transform: capitalize;
    color: ${primaryColors?.colora9a9a9};
    &.Mui-selected {
      color: ${primaryColors?.color201C1C};
    }
  }
  .MuiTabs-indicator {
    background-color: ${primaryColors?.primary};
    z-index: 2;
  }
  .MuiTabs-scroller{
    &::after{
      content: "";
      width: 100%;
      height: 1px;
      background-color: ${primaryColors?.color161f21};
      opacity: .37;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
  }
    }
   
  }
 
`;
