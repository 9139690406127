import React from "react";

const StockIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6817 6.08516L10.1613 6.60551L8.44356 4.8873C8.16969 4.62607 7.80576 4.48034 7.42729 4.48034C7.04882 4.48034 6.68489 4.62607 6.41102 4.8873L6.32813 4.97019C6.23692 5.05741 6.11558 5.10608 5.98937 5.10608C5.86317 5.10608 5.74183 5.05741 5.65062 4.97019L3.21991 2.53661C3.17571 2.49084 3.12284 2.45434 3.06438 2.42923C3.00592 2.40412 2.94305 2.3909 2.87943 2.39035C2.81581 2.38979 2.75271 2.40192 2.69383 2.42601C2.63494 2.4501 2.58145 2.48568 2.53646 2.53067C2.49147 2.57565 2.45589 2.62915 2.4318 2.68804C2.40771 2.74692 2.39558 2.81002 2.39614 2.87364C2.39669 2.93726 2.40991 3.00013 2.43502 3.05859C2.46013 3.11705 2.49663 3.16992 2.5424 3.21412L4.97263 5.64483C5.24643 5.90616 5.61039 6.05196 5.98889 6.05196C6.3674 6.05196 6.73135 5.90616 7.00516 5.64483L7.08806 5.56193C7.17927 5.47471 7.30061 5.42604 7.42681 5.42604C7.55301 5.42604 7.67435 5.47471 7.76557 5.56193L9.48379 7.28303L8.96343 7.80338C8.89644 7.87039 8.85083 7.95575 8.83235 8.04869C8.81387 8.14162 8.82336 8.23794 8.85961 8.32548C8.89587 8.41302 8.95727 8.48785 9.03604 8.5405C9.11482 8.59315 9.20744 8.62126 9.30219 8.62128H10.7808C10.9715 8.62128 11.1543 8.54556 11.289 8.41077C11.4238 8.27599 11.4996 8.09318 11.4996 7.90256V6.42056C11.4989 6.3261 11.4703 6.23394 11.4174 6.15568C11.3645 6.07741 11.2896 6.01653 11.2022 5.98069C11.1148 5.94485 11.0188 5.93564 10.9261 5.95423C10.8335 5.97282 10.7485 6.01837 10.6817 6.08516Z"
        fill="#E64A4A"
      />
      <path
        d="M11.0204 10.5297H2.39573C2.0145 10.5297 1.64888 10.3783 1.37931 10.1087C1.10974 9.83913 0.958292 9.47351 0.958292 9.09228V0.479146C0.958292 0.352069 0.907811 0.230196 0.817954 0.140339C0.728096 0.0504813 0.606224 0 0.479146 0C0.352069 0 0.230196 0.0504813 0.140339 0.140339C0.0504813 0.230196 0 0.352069 0 0.479146L0 9.09228C0.000760816 9.72743 0.253412 10.3364 0.702534 10.7855C1.15166 11.2346 1.76058 11.4872 2.39573 11.488H11.0204C11.1474 11.488 11.2693 11.4375 11.3592 11.3477C11.449 11.2578 11.4995 11.1359 11.4995 11.0089C11.4995 10.8818 11.449 10.7599 11.3592 10.6701C11.2693 10.5802 11.1474 10.5297 11.0204 10.5297Z"
        fill="#E64A4A"
      />
    </svg>
  );
};

export default StockIcon;
