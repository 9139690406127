import { Box,  List,  ListItem,  Stack, StackProps, Typography } from '@mui/material'
import { historyList } from 'json/mock/demo.mock'
import React from 'react'
import { EachHistoryItemWrapper, HistorySecWrapper } from 'styles/StyledComponents/HistorySecWrapper'
import CustomButtonPrimary from 'ui/CustomButtons/CustomButtonPrimary'
import DownloadIcon from 'ui/Icons/DownloadIcon'


export interface HistoryItemProps{
    transactionType:'Received' | 'Trade',
    date:string;
    amount:string;
    currency:string;
    info?:string
    icon:string;
}

const EachHistoryItem:React.FC<HistoryItemProps & StackProps> = ({transactionType,date,amount,currency,info,icon,...props}) =>{
    return(
        <EachHistoryItemWrapper direction='row' alignItems='center' justifyContent='space-between' {...props}>
            <Stack direction='row' alignItems='center' className='icon_stack'>
                <i>
                    <img src={icon} alt='icon'/>
                </i>
                <Box className='icon_content'>
                    <Typography variant='h5'>{transactionType}</Typography>
                    <Typography>{date}</Typography>
                </Box>
            </Stack>
            <Box className='history_transaction_details'>
                <Typography variant='h5'>${amount}{currency}</Typography>
                <Typography>{info}</Typography>
            </Box>
        </EachHistoryItemWrapper>
    )
}

const HistorySec = () => {
  return (
    <HistorySecWrapper className='cmn_block'>
        <Stack direction='row' alignItems='center' justifyContent='space-between' className='title_stack'>
            <Typography variant='h2'>History</Typography>
            <Stack direction='row' alignItems='center' justifyContent='space-between' className='title_stack_rgt'>
                <CustomButtonPrimary variant='outlined' color='info' startIcon={<DownloadIcon/>}>
                Generate tax
                </CustomButtonPrimary>
                <CustomButtonPrimary variant='contained' color='primary'>
                See all
                </CustomButtonPrimary>
            </Stack>
        </Stack>
        <Box className='history_sec_body'>
            <List disablePadding>
                {
                    historyList?.map((item,index)=>(
                        <ListItem disablePadding key={index}>
                            <EachHistoryItem {...item}/>
                        </ListItem>
                    ))
                }
            </List>
        </Box>
    </HistorySecWrapper>
  )
}

export default HistorySec